@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/*=================================*/
/*        PALETA DE COLORES       */
/*=================================*/
:root {
    /*--background--color: #ECEFF180;*/
    --background--color: #E4ECEF;
    --width-img-input: 210px;
    --height-img-input: 250px;
}

body {
    background-color: var(--background--color);
}
.menu-container{
    background-color: #F7F5FF;
}
.text-selected-menu{
    color: #09AB68;
    font-weight: bold;
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 240 10% 3.9%;
        --card: 0 0% 100%;
        --card-foreground: 240 10% 3.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 240 10% 3.9%;
        --primary: 240 5.9% 10%;
        --primary-foreground: 0 0% 98%;
        --secondary: 240 4.8% 95.9%;
        --secondary-foreground: 240 5.9% 10%;
        --muted: 240 4.8% 95.9%;
        --muted-foreground: 240 3.8% 45%;
        --accent: 240 4.8% 95.9%;
        --accent-foreground: 240 5.9% 10%;
        --destructive: 0 72% 51%;
        --destructive-foreground: 0 0% 98%;
        --border: 240 5.9% 90%;
        --input: 240 5.9% 90%;
        --ring: 240 5.9% 10%;
        --radius: 0.5rem;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground font-body;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-heading;
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 38px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #212121;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}


.animated-text {
    animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        /*transform: translateY(0);*/
        opacity: 90%;
    }

    40% {
        /*transform: translateY(-30px);*/
        opacity: 20%;
    }
}


.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #ffffff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.fadeIn {
    animation: 0.5s fadeIn forwards;
}

.fadeOut {
    animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-20px, 0);
    }

    to {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    to {
        transform: translate(-20px, 0);
        opacity: 0;
    }
}

.container {
    width: 150px;
    height: 150px;
    display: grid;
    overflow: hidden;
    margin: 0;
    list-style: none;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
}

.item {
    background: red;
    border-radius: 100%;
}

.show-btn-img:hover #btn-img {
    content: "";
    width: 100%;
    z-index: 3;
    height: 100%;
    position: absolute;
    display: block !important;
    background: rgba(141, 141, 141, 0.28);
}


.show-btn-img:hover #btn-img2 {
    content: "";
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.flex-basis {
    flex-basis: 200 !important;
}

.container-form {
    display: flex;
    gap: 25px !important;
    flex-wrap: wrap;
}

.item-input {
    flex-grow: 1;
    width: 250px;
    flex-basis: 400 !important;
    gap: 10px;
}

.item-image {
    flex-grow: 2;
    width: var(--width-img-input);
    height: var(--height-img-input);
    flex-basis: 400 !important;
}

.container-img-input {
    position: absolute;
    width: 100%;
    height: calc(var(--height-img-input) - 2px);
    top: 0;
    left: 0;
    margin: auto;
    display: flex;
}

.container-img-input img {
    height: calc(var(--height-img-input) - 0.24rem);
    margin: 0.5rem auto auto auto;
}

.container-img-input .container-img-input2 {
    position: absolute;
    width: 100%;
    height: calc(var(--height-img-input) - 2px);
    top: 0;
    left: 0;
    margin: 0.5rem 0 auto;
    opacity: 0;
    transition: .5s;
}

.container-img-input:hover .container-img-input2 {
    cursor: pointer;
    opacity: 1;
}

.container-forms {
    display: grid;
    gap: 10px;
    flex-basis: 250px;
    grid-template: repeat(3, 1fr);
    grid-auto-flow: column;
}

.container-form-input-img {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 5;
    position: relative;
    height: auto;
}

.container-form-input {
    flex-basis: 250px;
}

.flex-root {
    display: flex-root;
}

.img-fit {
    object-fit: contain;
}

#label-img:hover {
    content: "";
    border-radius: 0.75rem;
    background-color: rgba(197, 197, 197, 0.272);
}

.product-select:hover .icon-del-item {
    display: inline;
}

.icon-del-item {
    display: none;
}

.backdrop-blur-sm {
    backdrop-filter: none !important;
}

.text-anullation {
    width: 96%;
    size: 24px !important;
    z-index: 3;
    text-align: center;
    margin-top: 32%;
    transform: rotate(-45deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(-45deg);
    /* Firefox */
    -moz-transform: rotate(-45deg);
    /* IE */
    -ms-transform: rotate(-45deg);
    /* Opera */
    -o-transform: rotate(-45deg);
}
.leading-1 {
    line-height: 1;
}

.hidden-svg svg {
    display: none;
}

.hidden-svg:hover svg {
    display: block;
}

.hover-select {
    border: none;
}

.hover-select:hover {
    border-bottom: 1px solid #bbbec0;;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.blinking-div {
    animation: blink 3s infinite;
}

.input-table {

}



.custom-input {
    border: 1px solid #ccc;
    width: 100%;
    padding: 8px;
    color: initial;
    /*height: 25px;*/
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
    outline: none;
    transition: border-color 0.3s;
}

.custom-input:focus {
    border-color: #8a8a8a; /* Color del borde cuando el input está en foco */
}

/*table {*/
/*    border-collapse: collapse;*/
/*    width: 100%;*/
/*}*/

/*td, th {*/
/*    border: 1px solid #dddddd;*/
/*    text-align: left;*/
/*    padding: 2px;*/
/*}*/
.error {
    color: red;
    font-size: 10px;
}
/*tr:nth-child(even) {*/
/*    background-color: #dddddd;*/
/*}*/


.top-3-button {
    top: 0.595rem;
}

.right-0-5 {
    right: 1px ;
}


.tooltip-custom {
    position: relative;
    display: inline-block;
}

.tooltip-custom .tooltiptext-custom {
    visibility: hidden;
    width: 120px;
    background-color: #eeeeee;
    color: #171717;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip-custom:hover .tooltiptext-custom {
    visibility: visible;
}

.background-login{
    background-image: url("assets/img/plantalogin.webp");
    background-position: center;
}
.grid-inputs-precio {
    display: grid;
    gap: 5px;
    flex-basis: 150px;
    grid-template-columns: repeat(1, 80px);
    grid-auto-flow: row;
}
